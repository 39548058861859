import { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ReactComponent as CloseButton } from '@/assets/svgs/preview-image-close-btn.svg';
import { ReactComponent as PrevButton } from '@/assets/svgs/prev-icon.svg';
import { ReactComponent as NextButton } from '@/assets/svgs/next-icon.svg';
// @ts-ignore
import ImageZoom from 'react-image-zooom';
import { useRotateImageMutation } from '@/state/slices/globals/globalsApiSlice';
import { toast } from 'react-toastify';
import Spinner from 'react-bootstrap/Spinner';
import './PreviewImage.scss';

const PreviewImage = (props: any) => {
  const { show, src, id, title, handleClose, updateURL } = props;
  const [rotation, setRotation] = useState<number>(0);
  const [isPortrait, setIsPortrait] = useState<boolean>(false);
  const [imageRotateURL, setImageRotateURL] = useState<string>('');
  const [imageOpt, setImageOpt] = useState<{
    width: number;
    height: number;
    ratio: string;
    pathname: string;
  }>({
    width: 0,
    height: 0,
    ratio: '3/4',
    pathname: '',
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [rotateImage, { isLoading }] = useRotateImageMutation();

  const isSmallScreen = useMemo<boolean>(() => {
    return windowWidth <= 500;
  }, [windowWidth]);

  useEffect(() => {
    handleImageSize();
  }, [imageOpt?.width, imageOpt.height])

  useEffect(() => {
    if (!show) {
      setImageRotateURL('');
    }
  }, [show]);

  useEffect(() => {
    const img = new Image();
    img.src = imageRotateURL !== '' ? imageRotateURL : src;

    img.onload = () => {
      const imageURL = new URL(img.src);
      const imageWidth = img.naturalWidth;
      const imageHeight = img.naturalHeight;

      const gcd = (a: number, b: number): number => (b === 0 ? a : gcd(b, a % b));
      const commonDivisor = gcd(imageWidth, imageHeight);
      const imageRatio = `${imageWidth / commonDivisor}/${imageHeight / commonDivisor}`;

      setIsPortrait(imageHeight > imageWidth);
      setImageOpt({
        width: imageWidth,
        height: imageHeight,
        ratio: imageRatio,
        pathname: imageURL.pathname,
      });
    };
  }, [src, imageRotateURL]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleImageSize = () => {
    const figureElement = document.getElementById('custom-img');

    if (figureElement) {
      figureElement.style.maxWidth = `${vwToPixels(100) - 32 - 30}px`;
      figureElement.style.maxHeight = `${vhToPixels(100) - 32 - 62 - 30 - 20 - 53}px`;
      figureElement.style.aspectRatio = imageOpt?.ratio;
      figureElement.style.borderRadius = '7px';
    }
  }

  const handleRotateImage = async (key: string) => {
    const newRotation = key === 'right' ? 270 : 90;

    const payload = {
      image_path: imageOpt.pathname,
      angle: newRotation.toString(),
    };

    const result: any = await rotateImage(payload);

    if ('data' in result) {
      const URL = result?.data?.image_url;

      setImageRotateURL(URL);
      updateURL(URL);
    } else if (result?.error) {
      toast.error(result?.error?.data?.message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        progress: undefined,
        theme: 'light',
      });
    }

    setRotation(newRotation);
  };

  const vhToPixels = (vh: number): number => {
    const viewportHeight = window.innerHeight;
    return (vh * viewportHeight) / 100;
  };

  const vwToPixels = (vw: number): number => {
    const viewportWidth = window.innerWidth;
    return (vw * viewportWidth) / 100;
  };

  const getImageSize = () => {
    return {
      maxWidth: vwToPixels(100) - 32 - 30,
      maxHeight: vhToPixels(100) - 32 - 62 - 30 - 20 - 53,
      aspectRatio: imageOpt?.ratio,
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        fullscreen
        className="preview-image-modal"
      >
        <Modal.Body className="preview-image-container">
          <div className="preview-image-box">
            <div className="preview-image-box-header" id={'header-container'}>
              <p className="preview-image-box-header-title">
                {id && <span>{id}</span>} {title && <span>- {title}</span>}
              </p>
              <div>
                <CloseButton
                  onClick={handleClose}
                  style={{ width: 30, height: 30, cursor: 'pointer' }}
                />
              </div>
            </div>
            <div className="preview-image-box-content">
              <div className={`image-container ${isSmallScreen ? 'disable-zoom' : ''}`}>
                {isLoading ? (
                  <tr className="loading-row">
                    <Spinner animation="border" variant="primary" />
                  </tr>
                ) : (
                  <div style={{ maxWidth: getImageSize()?.maxWidth, maxHeight: getImageSize()?.maxHeight, aspectRatio: getImageSize()?.aspectRatio }}>
                    <ImageZoom 
                    id={'custom-img'}
                    src={imageRotateURL !== '' ? imageRotateURL : src}
                    alt="image"
                    width={'100%'}
                    height={'100%'}
                    />      
                  </div>
                )}
              </div>
              <div className="image-controls">
                <PrevButton
                  onClick={() => handleRotateImage('left')}
                  style={{ width: 24, height: 24, cursor: 'pointer' }}
                />
                <div className="separator"></div>
                <NextButton
                  onClick={() => handleRotateImage('right')}
                  style={{ width: 24, height: 24, cursor: 'pointer' }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PreviewImage;
